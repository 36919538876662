import Lottie from "lottie-react";

export function BaseLottie({
  animationData,
  loop = false,
  style = { cursor: "default" },
}: BaseLottieProps) {
  return <Lottie animationData={animationData} loop={loop} style={style} />;
}

export interface BaseLottieProps {
  animationData?: AnimationData;
  width?: number | string;
  height?: number | string;
  loop?: boolean;
  style?: React.CSSProperties;
}

interface AnimationData {
  v: string;
  meta: {
    g: string;
    a: string;
    k: string;
    d: string;
    tc: string;
  };
  fr: number;
  ip: number;
  op: number;
  w: number;
  h: number;
  nm: string;
  ddd: number;
  assets: {
    id: string;
    w: number;
    h: number;
    u: string;
    p: string;
    e: number;
  }[];
  layers: Array<unknown>;
  markers: Array<never>;
}
