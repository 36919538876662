import { Icon } from "../components/Icon";
import { Images } from "../components/Images";

export function NothingSelected() {
  return (
    <>
      <Icon source={Images.NoImage} className="mt-[5.5rem] mb-[1.5rem]" />

      <div className="text-sm font-light text-gray-500">선택 없음</div>
    </>
  );
}
