import { useEffect } from 'react';
import Prism from "prismjs";
import 'prismjs/components/prism-json' // Language
import 'prismjs/themes/prism.css' // Theme

export interface JsonViewProps {
  json: any;
}

export function JsonView({
  json,
}: JsonViewProps): JSX.Element {
  useEffect(() => {
    Prism.highlightAll();
  }, [json]);

  return (
    <div className="mb-5 text-xs font-light text-gray-700">
      <code className="language-json" style={{ backgroundColor: "transparent", whiteSpace: "pre" }}>{ JSON.stringify(json, null, 2) }</code>
    </div>
  )
}