import type { SVGProps } from "react";

export function UploadImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9773 12.3956H17.2728V10.6911C17.2728 10.1286 16.8125 9.66833 16.25 9.66833C15.6875 9.66833 15.2273 10.1286 15.2273 10.6911V12.3956H13.5227C12.9602 12.3956 12.5 12.8558 12.5 13.4183C12.5 13.9808 12.9602 14.4411 13.5227 14.4411H15.2273V16.1456C15.2273 16.7081 15.6875 17.1683 16.25 17.1683C16.8125 17.1683 17.2728 16.7081 17.2728 16.1456V14.4411H18.9773C19.5398 14.4411 20 13.9808 20 13.4183C20 12.8558 19.5398 12.3956 18.9773 12.3956Z"
      />
      <path d="M14.0219 7.53765C14.0219 8.18471 13.4925 8.71412 12.8454 8.71412C12.1984 8.71412 11.6689 8.18471 11.6689 7.53765C11.6689 6.89059 12.1984 6.36118 12.8454 6.36118C13.4925 6.36118 14.0219 6.89059 14.0219 7.53765Z" />
      <path d="M2.21848 2.83179H16.5292C17.0914 2.83179 17.5514 3.3612 17.5514 4.00826V8.82175C17.1825 8.56311 16.7336 8.41116 16.25 8.41116C15.9321 8.41116 15.6291 8.47685 15.354 8.59537V5.05387H3.43164V13.42L6.51874 9.0779L9.37383 12.2436L11.0961 10.036L12.6848 11.2986C11.8452 11.6329 11.25 12.4541 11.25 13.4112C11.25 14.664 12.2699 15.6839 13.5227 15.6839H13.9773V16.1384C13.9773 16.424 14.0303 16.6974 14.1269 16.9494H2.21848C1.65628 16.9494 1.19629 16.42 1.19629 15.773V4.00826C1.19629 3.3612 1.65628 2.83179 2.21848 2.83179Z" />
    </svg>
  );
}
