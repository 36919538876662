import { useEffect, useMemo, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { useDemoResult } from "../hooks/useDemoResult";
import { useImageTool } from "../hooks/useImageTool";
import type { Point } from "../types/demo";
import { OcrInferenceResult } from "../types/ocrOacResponse";
import { classnames } from "../utils/classnames";
import { NothingSelected } from "./NothingSelected";
import { InferenceMenuId } from "../pages/home";
import { OcrForLlmInferenceResult } from "../types/ocrForLlmOacResponse";
import {
  ContentField,
  ExtractorInferenceResult,
  GroupField,
} from "../types/extractorOacResponse";
import { WARNING_CONFIDENCE_THRESHOLD } from "../utils/threshold";

export interface ParsingImageViewProps {
  image: string;
  cssTransform: string;
  onResizeCanvas: (size: { width: number; height: number }) => void;
  onMoveImage: (offset: { x: number; y: number }) => void;
  onImageSizeChanged: (size: { width: number; height: number }) => void;
  allowBoxClick?: boolean;
  inferenceMenuId: InferenceMenuId;
  infering?: boolean;
}

interface BoxDrawing {
  id: string;
  points: [Point, Point, Point, Point];
  theme: "default" | "warning";
  // groupIds: string[];
  // entity: string | null;
}

interface Theme {
  default: {
    fill: string;
    opacity: number;
  };
  hovered: {
    fill: string;
    opacity: number;
  };
  selected: {
    fill: string;
    opacity: number;
  };
}

const BoxTheme: Theme = {
  default: {
    fill: "relative stroke-2 stroke-grey-400",
    opacity: 0,
  },
  hovered: {
    fill: "relative stroke-2 stroke-grey-600 fill-grey-600",
    opacity: 0.4,
  },
  selected: {
    fill: "relative stroke-2 stroke-grey-400 fill-grey-400",
    opacity: 0.2,
  },
};

const WarningBoxTheme: Theme = {
  default: {
    fill: "relative stroke-2 stroke-red-400",
    opacity: 0,
  },
  hovered: {
    fill: "relative stroke-2 stroke-red-600 fill-red-600",
    opacity: 0.4,
  },
  selected: {
    fill: "relative stroke-2 stroke-red-400 fill-red-400",
    opacity: 0.2,
  },
};

export function ParsingImageView({
  image,
  cssTransform,
  onResizeCanvas,
  onMoveImage,
  onImageSizeChanged,
  allowBoxClick = true,
  inferenceMenuId,
  infering = false,
}: ParsingImageViewProps): JSX.Element {
  const [imageSource, setImageSource] = useState<string>();
  const [svgWidth, setSvgWidth] = useState<number>(0);
  const [svgHeight, setSvgHeight] = useState<number>(0);

  const canvasRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<SVGSVGElement>(null);

  const { result, hover, select } = useDemoResult();

  const getBoxTheme = (boxId: string, themeName: string) => {
    const theme = themeName === "warning" ? WarningBoxTheme : BoxTheme;

    if (hover.hoveredBoxIds && hover.hoveredBoxIds.includes(boxId))
      return theme.hovered; // 반드시 hover 가 selected 에 앞서야 한다.
    if (select.selectedBoxIds && select.selectedBoxIds.includes(boxId))
      return theme.selected;
    return theme.default;
  };

  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  const imageTool = useImageTool(canvasRef, imageRef, onMoveImage);

  useEffect(() => {
    // HACKs
    // wrapper 와 svg 의 width, height 값이 같으면
    // resize event 일어났을때도 wrapper 내부의 svg 크기 때문에 wrapper 크기가 변동되지 않는다.
    // 그래서 크기 변화 감지를 위해 10 정도 더 작게 svg 를 만들어서 감지한다.
    const canvasWidth = width - 10;
    const canvasHeight = height - 10;
    setSvgWidth(canvasWidth);
    setSvgHeight(canvasHeight);
    onResizeCanvas({ width: canvasWidth, height: canvasHeight });
  }, [width, height, onResizeCanvas]);

  const boxes: BoxDrawing[] = useMemo(() => {
    if (infering || !result) return [];
    switch (inferenceMenuId) {
      case "ocr":
        return (
          (result as OcrInferenceResult)?.words?.map(box => {
            return {
              id: String(box.id),
              points: box.boundingBox.vertices.map(v => [v.x, v.y]),
            } as unknown as BoxDrawing;
          }) ?? []
        );
      case "extractor":
        // TODO: [daehyun] KVS extractor의 경우에는 boundingBox가 나오지 않는다.
        // 해당 예외처리를 추가한다.
        return (
          (result as ExtractorInferenceResult)?.flatMap(field => {
            if (field.type === "group") {
              const f = field as GroupField;
              return f.properties?.flatMap(property => {
                return property.boundingBoxes
                  ? property.boundingBoxes.flatMap(box => {
                      return {
                        id: String(property.id),
                        points: box.vertices.map(v => [v.x, v.y]),
                        theme:
                          property.confidence < WARNING_CONFIDENCE_THRESHOLD
                            ? "warning"
                            : "default",
                      } as unknown as BoxDrawing;
                    })
                  : [];
              });
            }
            const f = field as ContentField;
            return f.boundingBoxes
              ? f.boundingBoxes.flatMap(box => {
                  return {
                    id: String(field.id),
                    points: box.vertices.map(v => [v.x, v.y]),
                    theme:
                      f.confidence < WARNING_CONFIDENCE_THRESHOLD
                        ? "warning"
                        : "default",
                  } as unknown as BoxDrawing;
                })
              : [];
          }) ?? []
        );
      case "ocrForLlm":
        const paragraphs = (result as OcrForLlmInferenceResult)?.paragraphs;
        if (!paragraphs) return [];
        return Object.entries(paragraphs).map(([key, value]) => {
          return {
            id: String(key),
            points: value.points.map(v => [v[0], v[1]]),
          } as unknown as BoxDrawing;
        });
      default:
        return [];
    }
  }, [result, infering, inferenceMenuId]);

  useEffect(() => {
    if (!image) return;
    const newImageSource = "data:image/jpeg;base64," + image;
    setImageSource(newImageSource);
  }, [image]);

  return (
    <div
      className={classnames({
        "relative grid content-center justify-items-center h-full": true,
      })}
      ref={ref}
    >
      {image ? (
        <div
          className={`relative overflow-hidden ${imageTool.cursor}`}
          ref={canvasRef}
        >
          <svg
            width={svgWidth}
            height={svgHeight}
            viewBox={`0 0 ${svgWidth} ${svgHeight}`}
            style={{
              transform: cssTransform,
              overflow: "visible",
              transformOrigin: "top left",
            }}
            ref={imageRef}
          >
            <image
              href={imageSource}
              onLoad={e => {
                onImageSizeChanged({
                  width: e.currentTarget.getBBox().width,
                  height: e.currentTarget.getBBox().height,
                });
              }}
            />
            {boxes.map((box: BoxDrawing) => {
              const theme = getBoxTheme(box.id, box.theme);
              return (
                <g key={box.id + box.points.join(" ")}>
                  <polygon
                    className={theme ? theme.fill : ""}
                    key={box.id}
                    points={box.points.join(" ")}
                    onMouseEnter={() => hover.setHoveredBoxIds([box.id])}
                    onMouseLeave={() => hover.setHoveredBoxIds([])}
                    fillOpacity={theme.opacity}
                    onClick={() => {
                      if (allowBoxClick) {
                        select.setSelectedBoxIds([box.id]);
                      }
                    }}
                  />
                </g>
              );
            })}
          </svg>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <NothingSelected />
        </div>
      )}
    </div>
  );
}
