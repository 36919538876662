import type { IconName } from "../Icon";

export enum ButtonStyle {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Danger = "danger",
  Ghost = "ghost",
  GhostSecondary = "ghost-secondary",
  GhostDanger = "ghost-danger",
  Dashed = "dashed",
  Basic = "basic",
  BasicDark = "basic-dark",
  Trivial = "trivial",
}

export enum ButtonSize {
  L = "L",
  M = "M",
  S = "S",
  XS = "XS",
}

export type SideContent = IconName | React.ReactNode;

type MouseEventHandler = React.MouseEventHandler;

export interface ButtonProps {
  /**
   * The disabled property of Button.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * `type` attribute of typical HTML button.
   *
   * @default 'button'
   */
  type?: React.ComponentProps<"button">["type"];

  /**
   * The size of Button : L, M, S
   *
   * @default ButtonSize.M
   */
  size?: ButtonSize;

  /**
   * The style of Button : primary, secondary, tertiary, ghost ...
   *
   * @default ButtonStyle.Primary
   */
  style?: ButtonStyle;

  /**
   * The text content in the button.
   *
   * Do not pass `text` prop if it is an icon-only button.
   */
  text?: string | React.ReactNode;

  /**
   * The left content of the button.
   *
   * It can be icon or any React node.
   */
  leftContent?: SideContent;

  /**
   * The right content of the button.
   *
   * It can be icon or any React node.
   */
  rightContent?: SideContent;

  /**
   * The handler to be executed when the button is clicked.
   */
  onClick?: MouseEventHandler;

  /**
   * A button name in a form
   */
  name?: string;

  /**
   * A button value which is going to be wired to a server binding with the
   * button name.
   */
  value?: React.ButtonHTMLAttributes<HTMLButtonElement>["value"];

  /**
   * Whether the button should take up the full width of its container.
   * @default false
   */
  widthFull?: boolean;

  /**
   * The className of the button.
   */
  className?: string;

  /**
   * Type of left borders : rounded, square
   * A type of left border.
   * @default "rounded";
   */
  leftBorderType?: "rounded" | "square";

  /**
   * Type of left borders : rounded, square
   * A type of left border.
   * @default "rounded";
   */
  rightBorderType?: "rounded" | "square";
}
