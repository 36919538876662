import { InferenceMenuId as MenuId } from "../../pages/home";
import { classnames } from "../../utils/classnames";
import { Icon, Icons } from "../Icon";

export interface MenuItem {
  id: MenuId;
  title: string;
  description: string;
}

export interface HeaderProps {
  menuItems: MenuItem[];
  selectedMenuId: MenuId;
  handleMenuItemSelect: (menuId: MenuId) => void;
}

export function Header({
  menuItems,
  selectedMenuId,
  handleMenuItemSelect,
}: HeaderProps) {
  return (
    <div className="h-[4rem] bg-white rounded-t flex flex-col justify-center items-start gap-3">
      <div className="flex flex-row items-center justify-start gap-2">
        <Icon source={Icons.Document} />
        {menuItems.map((menuItem, idx) => (
          <div
            // onClick={() => handleMenuItemSelect(menuItem.id)}
            className={classnames({
              "font-normal text-xl text-black leading-normal": true,
              "text-opacity-90": selectedMenuId === menuItem.id,
              "text-opacity-50 ": selectedMenuId !== menuItem.id,
            })}
            key={idx}
          >
            {menuItem.title}
          </div>
        ))}
      </div>
      {/* <div className="text-sm font-normal leading-tight text-black text-opacity-50">
        {menuItems.find(menuItem => menuItem.id === selectedMenuId)
          ?.description || ""}
      </div> */}
    </div>
  );
}
