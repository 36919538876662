import React, { useContext } from "react";
import { InferenceResult } from "../types/demo";

export interface DemoResult {
  result: InferenceResult | null;
  hover: {
    hoveredBoxIds: string[];
    setHoveredBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
    hoveredEntityNames?: string[];
    setHoveredEntityNames?: React.Dispatch<React.SetStateAction<string[]>>;
  };
  select: {
    selectedBoxIds: string[];
    setSelectedBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
  };
}

export const emtpyDemoResult: DemoResult = {
  result: null,
  hover: {
    hoveredBoxIds: [],
    setHoveredBoxIds: () => {},
  },
  select: {
    selectedBoxIds: [],
    setSelectedBoxIds: () => {},
  },
};

const DemoResultContext = React.createContext<DemoResult>(emtpyDemoResult);

export const DemoResultProvider = DemoResultContext.Provider;

export function useDemoResult(): DemoResult {
  return useContext(DemoResultContext);
}
