export function EmptyTable() {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="4" y="7.99976" width="1" height="14" fill="#C6C8D2" />
        <rect x="10" y="7.99976" width="1" height="14" fill="#C6C8D2" />
        <rect x="27" y="7.99976" width="1" height="14" fill="#C6C8D2" />
        <rect x="4" y="12" width="24" height="1" fill="#C6C8D2" />
        <rect x="4" y="8" width="24" height="1" fill="#C6C8D2" />
        <rect x="4" y="21" width="24" height="1" fill="#C6C8D2" />
        <rect x="4" y="16.5" width="24" height="1" fill="#C6C8D2" />
      </svg>
      <div className="mt-1 text-sm font-light text-gray-400">테이블 없음</div>
    </>
  );
}
