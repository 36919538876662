/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from "react";

export function Document(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="29"
      height="36"
      viewBox="0 0 29 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M2.84328 1.96125C2.89511 1.4322 3.39939 1.04272 3.96961 1.09132L27.143 3.06631C27.7132 3.11491 28.1334 3.58318 28.0816 4.11223L25.3286 32.2132C25.2768 32.7422 24.7725 33.1317 24.2023 33.0831L1.0289 31.1081C0.458674 31.0595 0.0384298 30.5913 0.0902603 30.0622L2.84328 1.96125Z"
        fill="black"
        fillOpacity="0.07"
      />
      <path
        d="M6.1668 5.023L13.6758 5.68892L13.5078 7.33454L5.99887 6.66862L6.1668 5.023Z"
        fill="black"
        fillOpacity="0.07"
      />
      <path
        d="M5.81858 8.22924L21.8348 9.64962L21.6668 11.2952L5.65064 9.87487L5.81858 8.22924Z"
        fill="black"
        fillOpacity="0.07"
      />
      <path
        d="M5.49852 11.3372L19.0683 12.5406L18.9004 14.1862L5.33058 12.9828L5.49852 11.3372Z"
        fill="black"
        fillOpacity="0.07"
      />
      <path
        d="M3.84434 26.6386L10.7266 27.2682L10.5533 28.9134L3.67109 28.2837L3.84434 26.6386Z"
        fill="black"
        fillOpacity="0.07"
      />
      <path
        d="M18.5744 26.0603L22.7038 26.4241L22.3484 29.9331L18.2189 29.5693L18.5744 26.0603Z"
        fill="black"
        fillOpacity="0.07"
      />
    </svg>
  );
}
