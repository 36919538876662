import type { SVGProps } from "react";

export function Check(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5294 6.03039L6.49908 13.0607L1.96875 8.53039L3.02941 7.46973L6.49908 10.9394L12.4688 4.96973L13.5294 6.03039Z"
      />
    </svg>
  );
}
