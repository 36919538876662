/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from "react";
import { useImageRendering } from "../hooks/useImageRendering";
import { DemoImageViewToolbar } from "../molecules/DemoImageViewToolbar";
import { ParsingImageView } from "../molecules/ParsingImageView";
import { ImageOacJson } from "../types/demo";
import { classnames } from "../utils/classnames";
import { InferenceMenuId } from "../pages/home";

export interface DemoImageViewProps {
  file: ImageOacJson;
  page: number;
  pageSelectHandler: (page: number) => void;
  image: string;
  imageScale: number;
  imageScaleChangeHandler: (scale: number) => void;
  allowBoxClick?: boolean;
  inferenceMenuId: InferenceMenuId;
  infering?: boolean;
}

export function DemoImageView({
  file,
  page,
  pageSelectHandler,
  image,
  imageScale,
  imageScaleChangeHandler,
  allowBoxClick = true,
  inferenceMenuId,
  infering = false,
}: DemoImageViewProps): JSX.Element {
  const [cssTransform, setCssTanform] = useState<string>("");
  const {
    canvasSizeChangeHandler,
    canvasObjectMoveHandler,
    imageSizeChangeHandler,
    rotateBy,
  } = useImageRendering(
    file,
    imageScale,
    imageScaleChangeHandler,
    setCssTanform,
  );

  return (
    <div
      className={classnames({
        "flex-1 bg-gray-100": true,
      })}
    >
      <div className="relative flex flex-col h-full">
        <ParsingImageView
          image={image}
          allowBoxClick={true}
          cssTransform={cssTransform}
          onResizeCanvas={canvasSizeChangeHandler}
          onMoveImage={canvasObjectMoveHandler}
          onImageSizeChanged={imageSizeChangeHandler}
          infering={infering}
          inferenceMenuId={inferenceMenuId}
        />
        <DemoImageViewToolbar
          fileVisible={!!image}
          imageScale={imageScale}
          onImageScaleChange={imageScaleChangeHandler}
          page={page}
          onPageSelect={pageSelectHandler}
          onRotate={() => rotateBy(90)}
        />
      </div>
    </div>
  );
}
