import { Check } from "./Check";
import { ChevronDown } from "./ChevronDown";
import { ChevronLeft } from "./ChevronLeft";
import { ChevronRight } from "./ChevronRight";
import { ChevronUp } from "./ChevronUp";
import { Document } from "./Document";
import { DownArrowColumn } from "./DownArrowColumn";
import { Download } from "./Download";
import { ErrorTriangle } from "./ErrorTriangle";
import { NoHistory } from "./NoHistory";
import { NoResult } from "./NoResult";
import { Plus } from "./Plus";
import { Reset } from "./Reset";
import { UploadImage } from "./UploadImage";
import { ZoomIn } from "./ZoomIn";
import { ZoomOut } from "./ZoomOut";

export const Icons = {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Document,
  DownArrowColumn,
  Download,
  ErrorTriangle,
  NoHistory,
  NoResult,
  Plus,
  Reset,
  UploadImage,
  ZoomIn,
  ZoomOut,
};

export type IconName = keyof typeof Icons;
