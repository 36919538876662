import type { SVGProps } from "react";

export function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 11H13.5V8C13.5 7.175 12.825 6.5 12 6.5C11.175 6.5 10.5 7.175 10.5 8V11H7.5C6.675 11 6 11.675 6 12.5C6 13.325 6.675 14 7.5 14H10.5V17C10.5 17.825 11.175 18.5 12 18.5C12.825 18.5 13.5 17.825 13.5 17V14H16.5C17.325 14 18 13.325 18 12.5C18 11.675 17.325 11 16.5 11Z"
      />
    </svg>
  );
}
