import {
  ExtractorOacJsonResponse,
  ImageExtractorOacJson,
  convertResponseToExtractorImageOacJson,
} from "../types/extractorOacResponse";
import {
  ImageOcrForLlmOacJson,
  OcrForLlmOacJsonResponse,
} from "../types/ocrForLlmOacResponse";
import {
  ImageOcrOacJson,
  OcrOacJsonResponse,
  convertResponseToImageOcrOacJson,
} from "../types/ocrOacResponse";
export function useImageUploader() {
  const uploadOcrFiles = async (selectedFiles: File[]) => {
    // TODO: 서버 주소를 환경변수로 설정
    const url = `${process.env.REACT_APP_BACKEND_URL}/ocr`;
    if (!selectedFiles || selectedFiles.length === 0) {
      return [];
    }
    const uploads = selectedFiles.map(async file => {
      const fd = new FormData();
      fd.append("image", file as Blob);

      try {
        const response = await fetch(url, {
          method: "POST",
          body: fd,
        });
        const image = (await response.json()) as OcrOacJsonResponse;
        return image;
      } catch (err) {
        console.error("err:", err);
        throw err;
      }
    });

    const files = (await Promise.all(uploads))
      .filter(response => response !== null)
      .map(response => convertResponseToImageOcrOacJson(response!));
    return files as ImageOcrOacJson[];
  };

  const uploadExtractorFiles = async (
    selectedFiles: File[],
    apiUrl: string,
    apiKey?: string,
  ) => {
    // TODO: 서버 주소를 환경변수로 설정
    const url = `${process.env.REACT_APP_BACKEND_URL}/extractor`;
    if (!selectedFiles || selectedFiles.length === 0) {
      return [];
    }
    const uploads = selectedFiles.map(async file => {
      const fd = new FormData();
      fd.append("apiUrl", apiUrl);
      fd.append("apiKey", apiKey || "");
      fd.append("image", file as Blob);

      try {
        const response = await fetch(url, {
          method: "POST",
          body: fd,
        });
        const image = (await response.json()) as ExtractorOacJsonResponse;
        return image;
      } catch (err) {
        console.error("err:", err);
        throw err;
      }
    });

    const files = (await Promise.all(uploads))
      .filter(response => response !== null)
      .map(response => convertResponseToExtractorImageOacJson(response!));
    return files as ImageExtractorOacJson[];
  };

  const uploadOcrForLlmFiles = async (selectedFiles: File[]) => {
    // TODO: 서버 주소를 환경변수로 설정
    const url = `${process.env.REACT_APP_BACKEND_URL}/layout/llm`;
    if (!selectedFiles || selectedFiles.length === 0) {
      return [];
    }
    const uploads = selectedFiles.map(async file => {
      const fd = new FormData();
      fd.append("image", file as Blob);

      try {
        const response = await fetch(url, {
          method: "POST",
          body: fd,
        });
        const image = (await response.json()) as OcrForLlmOacJsonResponse;
        return image;
      } catch (err) {
        console.error("err:", err);
        throw err;
      }
    });

    const files = (await Promise.all(uploads)).filter(
      response => response !== null,
    );
    return files as ImageOcrForLlmOacJson[];
  };

  return {
    uploadOcrFiles: uploadOcrFiles,
    uploadExtractorFiles: uploadExtractorFiles,
    uploadOcrForLlmFiles: uploadOcrForLlmFiles,
  };
}
