import type { SVGProps } from "react";

export function Download(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.7 6.3L3 7L8 12L13 7L12.3 6.3L8.5 10.1L8.5 1H7.5L7.5 10.1L3.7 6.3Z" />
      <path d="M13 14V12H14V14C14 14.6 13.6 15 13 15H3C2.4 15 2 14.6 2 14V12H3V14H13Z" />
    </svg>
  );
}
