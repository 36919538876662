import type { SVGProps } from "react";

export function Reset(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 9C11 11.8 8.8 14 6 14C3.2 14 1 11.8 1 9C1 6.2 3.2 4 6 4H9.1L7.3 5.8L8 6.5L11 3.5L8 0.5L7.3 1.2L9.1 3H6C2.7 3 0 5.7 0 9C0 12.3 2.7 15 6 15C9.3 15 12 12.3 12 9H11Z"
      />
    </svg>
  );
}
