export function removeKeysRecursively(obj: any, keysToRemove: string[]): any {
  if (Array.isArray(obj)) {
    // If it's an array, apply the function to each element
    return obj.map(item => removeKeysRecursively(item, keysToRemove));
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, remove the keys
    const newObj: any = {};
    for (const key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = removeKeysRecursively(obj[key], keysToRemove);
      }
    }
    return newObj;
  } else {
    // If it's neither an array nor an object, return it as is
    return obj;
  }
}
