import { classnames } from "../../utils/classnames";

export interface ToggleButtonProps {
  text: string;
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export function ToggleButton({
  text,
  active,
  disabled = false,
  onClick,
}: ToggleButtonProps): JSX.Element {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classnames({
        "bg-white px-3 py-1 rounded-full justify-start items-center gap-2.5 flex text-sm font-bold font-['ES Peak'] leading-normal":
          true,
        " text-black/90": active,
        " text-black/50": !active,
      })}
    >
      {text}
    </button>
  );
}
