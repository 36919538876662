import type { FC, ReactElement } from "react";
import React from "react";

export interface IconOptions {
  /**
   * The name of svgr icon
   */
  source: React.ElementType<React.SVGProps<SVGSVGElement>>;

  /**
   * The className of icon (matching with tailwind classes)
   */
  className?: string;
}

export type IconProps = IconOptions;

export const Icon: FC<IconProps> = ({
  source,
  className = "",
}): ReactElement => {
  return React.createElement(source, { className }, null);
};
