import React from "react";
import { classnames } from "../../utils/classnames";

// TODO: 규격 관련 기획 논의 및 반영 필요 (고정 vs 유연)
export type DialogSize = "xs" | "sm" | "md" | "lg" | "xl";

export interface DialogProps {
  children: JSX.IntrinsicElements["section"]["children"]; // 다이얼로그 내용
  isOpen: boolean; // 다이얼로그 열림 여부
  onClose: () => void; // 다이얼로그 닫힐 때 호출
  shouldCloseOnExternalClick: boolean; // 다이얼로그 바깥 클릭 시 다이얼로그 닫힘 동작 여부
  size: DialogSize; // 다이얼로그 규격
  fullHeight?: boolean;
  verticalAlign?: "start" | "center" | "end";
}

export function Dialog({
  children,
  isOpen,
  onClose,
  shouldCloseOnExternalClick,
  size,
  fullHeight,
  verticalAlign = "center",
}: DialogProps): JSX.Element | null {
  const getSizeStyle = () => {
    switch (size) {
      case "xs": {
        return "w-[444px]";
      }
      case "sm": {
        return "w-[600px]";
      }
      case "md": {
        return "w-[900px]";
      }
      case "lg": {
        return "w-[1200px]";
      }
      case "xl":
      default: {
        return `w-[calc(100vw-64px)] h-[calc(100vh-64px)]`;
      }
    }
  };

  // NOTE: hooks가 return 아래에 위치하지 않도록 주의
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classnames({
        "fixed inset-0 z-20 flex justify-center bg-black/10": true,
        "items-center": verticalAlign === "center",
        "items-start": verticalAlign === "start",
        "items-end": verticalAlign === "end",
      })}
      onClick={() => shouldCloseOnExternalClick && onClose()}
    >
      <div
        className={classnames({
          [getSizeStyle()]: true,
          "max-w-[calc(100vw-64px)] max-h-[calc(100vh-64px)] rounded bg-white flex flex-col border border-gray-200 shadow-xl":
            true,
          "min-h-[calc(100vh-64px)]": fullHeight,
        })}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

Dialog.Header = function DialogHeader({
  children,
}: JSX.IntrinsicElements["div"]) {
  return <div className="flex flex-col mx-6 mt-6 mb-2 gap-y-1">{children}</div>;
};

Dialog.Title = function DialogTitle({ children }: JSX.IntrinsicElements["dt"]) {
  return <dt className="text-xl font-medium text-gray-900">{children}</dt>;
};

Dialog.Description = function DialogDescription({
  children,
}: JSX.IntrinsicElements["dd"]) {
  return <dd className="font-light text-gray-500 text-ss">{children}</dd>;
};

function DialogContent({
  children,
}: Pick<React.ComponentPropsWithoutRef<"div">, "children">) {
  return (
    <div className="flex flex-col flex-1 m-6 overflow-auto gap-y-4">
      {children}
    </div>
  );
}
DialogContent.displayName = "Dialog.Content";
Dialog.Content = DialogContent;

function DialogContentItem({
  children,
}: Pick<React.ComponentPropsWithoutRef<"div">, "children">) {
  return <div>{children}</div>;
}
DialogContentItem.displayName = "Dialog.ContentItem";
Dialog.ContentItem = DialogContentItem;

function DialogFooter({
  children,
}: Pick<React.ComponentPropsWithoutRef<"div">, "children">) {
  return (
    <div className="flex flex-row items-center justify-end flex-none mx-6 my-4 gap-x-2">
      {children}
    </div>
  );
}
DialogFooter.displayName = "Dialog.Footer";
Dialog.Footer = DialogFooter;
