import { useState, useEffect } from "react";
import { Icon } from "../components/Icon";
import { Images } from "../components/Images";
import { parse } from 'node-html-parser';
import { classnames } from "../utils/classnames";
import Prism from "prismjs";
import 'prismjs/components/prism-json' // Language
import 'prismjs/themes/prism.css' // Theme

export interface OcrForLlmHtmlViewProps {
  html: string;
  hover: {
    hoveredBoxIds: string[];
    setHoveredBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
  };
  select: {
    selectedBoxIds: string[];
    setSelectedBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
  };
  disabled?: boolean;
}

export function OcrForLlmHtmlView({
  html,
  hover,
  select,
  disabled = false,
}: OcrForLlmHtmlViewProps): JSX.Element {
  useEffect(() => {
    Prism.highlightAll()
  }, [html]);

  const [isRowClicked, setIsRowClicked] = useState(false);

  const isHovered = (id: string) => {
    return hover.hoveredBoxIds.includes(id);
  };

  const isSelected = (id: string) => {
    return select.selectedBoxIds.includes(id);
  };

  if(html === "") {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Icon source={Images.NoImage} className="mt-[5.5rem] mb-[1.5rem]" />
        <div className="text-sm font-light text-gray-500">
          No text was read from the image
        </div>
      </div>
    );
  }

  // html 파싱하기
  const root = parse(html);
  const tags = root.getElementsByTagName("*");

  return (
    <table className="w-[calc(100%-1.5rem)] table-fixed">
      <tbody className="flex flex-col items-start justify-start flex-1 rounded">
        {tags.map((tag) => {
          const id = tag.id;
          if (!id) return null;

          return (
            <tr 
              className={classnames({
                "bg-indigo-400 bg-opacity-20 border-indigo-400 border-opacity-10":
                  !disabled && isHovered(id),
                "bg-white border-transparent": disabled || !isHovered(id),
                "border text-md px-2 py-1 font-normal text-left text-black text-opacity-90 break-words w-full rounded":
                  true,
              })}
              id={`row-${id}`}
              key={`${id}`}
              onMouseEnter={() =>
                hover.setHoveredBoxIds([
                  id,
                ])
              }
              onMouseLeave={() => hover.setHoveredBoxIds([])}
              onClick={() => {
                setIsRowClicked(true);
                select.setSelectedBoxIds([id]);
              }}
              >
              <code className="language-html" style={{ backgroundColor: "transparent" }}>{ tag.outerHTML }</code>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
