type CssName = string;

export function classnames(
  classNamesMap: Record<CssName, boolean | undefined | null>,
): string {
  return Object.keys(classNamesMap)
    .filter(className => {
      return !!classNamesMap[className];
    })
    .join(" ");
}

export function classlist(...nameList: (string | undefined | null)[]): string {
  return nameList.filter(Boolean).join(" ");
}
