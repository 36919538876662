import type { ComponentProps, ReactNode } from "react";
import { classnames } from "../../utils/classnames";

export interface TooltipProps {
  text: ReactNode;
  className?: string;
  noMaxWidth?: boolean;
  fixed?: boolean;
  arrowVisible?: boolean;
  arrowPosition?: "left" | "top" | "right" | "bottom";
  arrowClassName?: string;
}

export function Tooltip({
  text,
  className = "",
  noMaxWidth = false,
  arrowVisible = false,
  fixed = false,
  arrowPosition = "top",
  arrowClassName = "",
}: TooltipProps) {
  const groupVisible = "invisible group-hover:visible";

  return (
    <div
      className={classnames({
        "z-50 px-2 py-1 text-white text-xs font-light bg-gray-900 rounded whitespace-nowrap":
          true,
        "max-w-[27.75rem]": !noMaxWidth,
        "ml-1": arrowVisible && arrowPosition === "left",
        "mr-1": arrowVisible && arrowPosition === "right",
        "mt-1": arrowVisible && arrowPosition === "top",
        "mb-1": arrowVisible && arrowPosition === "top",
        [groupVisible]: true,
        absolute: !fixed,
        fixed,
        [className]: true,
      })}
    >
      {text}
      {arrowVisible && (
        <Arrow
          position={arrowPosition}
          className={arrowClassName}
          fixed={fixed}
        />
      )}
    </div>
  );
}

interface ArrowProps {
  position?: "left" | "top" | "right" | "bottom";
  className?: string;
  fixed?: boolean;
}

function Arrow({ position = "left", className = "" }: ArrowProps) {
  return (
    <div
      className={classnames({
        [className]: true,
        "w-[0.3125rem] h-[0.3125rem] bg-gray-900 rotate-45 absolute": true,
        "left-0 inset-y-1/2 -translate-x-1/2 -translate-y-1/2":
          position === "left",
        "right-0 inset-y-1/2 translate-x-1/2 -translate-y-1/2":
          position === "right",
        "inset-x-1/2 top-0 -translate-x-1/2 -translate-y-1/2":
          position === "top",
        "inset-x-1/2 bottom-0 -translate-x-1/2 translate-y-1/2":
          position === "bottom",
      })}
    />
  );
}

export interface TooltipWrapperProps {
  children: ComponentProps<"div">["children"];
  text: ReactNode;
  className?: string;
  noMaxWidth?: boolean;
  arrowVisible?: boolean;
  arrowPosition?: "left" | "top" | "right" | "bottom";
  arrowClassName?: string;
}

export function TooltipWrapper({
  children,
  text,
  className = "",
  noMaxWidth = false,
  arrowVisible = false,
  arrowPosition = "left",
  arrowClassName = "",
}: TooltipWrapperProps) {
  return (
    <div className="relative flex items-center justify-center w-full h-full group">
      {children}
      <Tooltip
        text={text}
        className={className}
        noMaxWidth={noMaxWidth}
        arrowVisible={arrowVisible}
        arrowPosition={arrowPosition}
        arrowClassName={arrowClassName}
      />
    </div>
  );
}
