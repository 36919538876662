import { useEffect, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Button, ButtonStyle, ToggleButton } from "../components/Button";
import { Icon, Icons } from "../components/Icon";
import { EmptyTable } from "../components/Images/EmptyTable";
import { useDemoResult } from "../hooks/useDemoResult";
import { JsonView } from "../molecules/JsonView";
import { OcrTextView } from "../molecules/OcrTextView";
import { ParsingResultTable } from "../molecules/ParsingResultTable";
import { InferenceMenuId } from "../pages/home";
import { InferenceResult } from "../types/demo";
import { OcrInferenceResult } from "../types/ocrOacResponse";
import { classnames } from "../utils/classnames";
import { downloadFile } from "../utils/downloadFile";
import { removeKeysRecursively } from "../utils/removeKeysRecursively";
import { OcrForLlmInferenceResult } from "../types/ocrForLlmOacResponse";
import { OcrForLlmHtmlView } from "../molecules/OcrForLlmHtmlView";

export type ViewMode =
  | "json"
  | "ocr-text"
  | "extractor-table"
  | "ocrForLlm-html";

interface DemoDetailViewProps {
  infering?: boolean;
  inferenceMenuId: InferenceMenuId;
}

export function DemoDetailView({
  infering = false,
  inferenceMenuId,
}: DemoDetailViewProps): JSX.Element {
  const [viewMode, setViewMode] = useState<ViewMode>();
  const { result, hover, select } = useDemoResult();

  const jsonForUser = useMemo(() => {
    const ocrKeysToHide = ["lines", "boundingBoxes"];
    const receiptKeysToHide = ["lines", "boundingBox", "boundingBoxes"];

    if (inferenceMenuId === "ocr") {
      return removeKeysRecursively(result, ocrKeysToHide);
    } else {
      return removeKeysRecursively(result, receiptKeysToHide);
    }
  }, [inferenceMenuId, result]);

  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const onkeydown = function (e: KeyboardEvent) {
      if (e.code === "Space") {
        setDragging(true);
      }
    };

    const onkeyup = function (e: KeyboardEvent) {
      if (e.code === "Space") {
        setDragging(false);
      }
    };

    window.addEventListener("keydown", onkeydown);
    window.addEventListener("keyup", onkeyup);

    return () => {
      window.removeEventListener("keydown", onkeydown);
      window.removeEventListener("keyup", onkeyup);
    };
  }, []);

  useEffect(() => {
    if (inferenceMenuId === "ocr") {
      setViewMode("ocr-text");
    } else if (inferenceMenuId === "extractor") {
      setViewMode("extractor-table");
    } else if (inferenceMenuId === "ocrForLlm") {
      setViewMode("ocrForLlm-html");
    } else {
      setViewMode("json");
    }
  }, [inferenceMenuId]);

  function downloadJson({
    json,
    fileNameWithoutExtenstion,
  }: DownloadParams): void {
    const jsonString = JSON.stringify(json, null, 0);
    const blob = new Blob([jsonString], { type: "application/json" });
    downloadFile({ blob, fileName: `${fileNameWithoutExtenstion}.json` });
  }

  function downloadOcrText({
    json,
    fileNameWithoutExtenstion,
  }: DownloadParams): void {
    const textString = (json as OcrInferenceResult).lines.reduce(
      (acc, value) => acc + value.text + "\n",
      "",
    ) as string;
    const blob = new Blob([textString], { type: "text/plain" });
    downloadFile({ blob, fileName: `${fileNameWithoutExtenstion}.txt` });
  }

  function downloadReceiptCsv({
    json,
    fileNameWithoutExtenstion,
  }: DownloadParams): void {
    return;
  }

  function downloadOcrForLlmHtml({
    json,
    fileNameWithoutExtenstion,
  }: DownloadParams): void {
    const textString = (json as OcrForLlmInferenceResult)?.html || "";
    const blob = new Blob([textString], { type: "text/plain" });
    downloadFile({ blob, fileName: `${fileNameWithoutExtenstion}.html` });
  }

  interface DownloadParams {
    json: InferenceResult | null;
    fileNameWithoutExtenstion: string;
  }

  function download({ json, fileNameWithoutExtenstion }: DownloadParams): void {
    if (viewMode === "json") {
      downloadJson({ json: jsonForUser, fileNameWithoutExtenstion });
    } else if (viewMode === "ocr-text") {
      downloadOcrText({ json, fileNameWithoutExtenstion });
    } else if (viewMode === "extractor-table") {
      downloadReceiptCsv({ json, fileNameWithoutExtenstion });
    } else if (viewMode === "ocrForLlm-html") {
      downloadOcrForLlmHtml({ json, fileNameWithoutExtenstion });
    }
  }

  return (
    <div
      className={classnames({
        "flex-1 overflow-y-hidden flex flex-col bg-white border border-black/5 rounded":
          true,
      })}
    >
      {!jsonForUser || infering ? null : (
        <div className="flex flex-row items-center justify-between w-full h-[3.25rem] border-b border-black/5 pl-3 pr-6 ">
          <div className="flex flex-row justify-start">
            {inferenceMenuId === "ocr" ? (
              <ToggleButton
                text="TEXT"
                active={viewMode === "ocr-text"}
                onClick={() => setViewMode("ocr-text")}
              />
            ) : null}
            {inferenceMenuId === "extractor" ? (
              <ToggleButton
                text="TEXT"
                active={viewMode === "extractor-table"}
                onClick={() => setViewMode("extractor-table")}
              />
            ) : null}
            {/* {inferenceMenuId === "ocrForLlm" ? (
              <ToggleButton
                text="HTML"
                active={viewMode === "ocrForLlm-html"}
                onClick={() => setViewMode("ocrForLlm-html")}
              />
            ) : null} */}
            {/* <ToggleButton
              text="JSON"
              active={viewMode === "json"}
              onClick={() => {
                setViewMode("json");
              }}
            /> */}
          </div>
          {/* <Button
            onClick={() =>
              download({
                json: result,
                fileNameWithoutExtenstion: "try_upstage_document_ai",
              })
            }
            text="Download"
            leftContent={<Icon source={Icons.Download} />}
            style={ButtonStyle.Secondary}
          /> */}
        </div>
      )}
      <section className="flex-1 pt-6 pb-5 pl-5 pr-6">
        {!jsonForUser || infering ? (
          <div className="flex flex-col items-center justify-center h-full p-5 bg-transparent">
            <EmptyTable />
          </div>
        ) : viewMode === "json" ? (
          <div
            className={classnames({
              "h-full bg-white px-5 pt-5": true,
            })}
          >
            <Scrollbars>
              <JsonView json={jsonForUser} />
            </Scrollbars>
          </div>
        ) : viewMode === "ocr-text" ? (
          <Scrollbars>
            <OcrTextView
              lines={(result as OcrInferenceResult).lines}
              hover={hover}
              select={select}
              disabled={dragging}
            />
          </Scrollbars>
        ) : viewMode === "extractor-table" ? (
          <Scrollbars>
            <ParsingResultTable disabled={dragging} />
          </Scrollbars>
        ) : viewMode === "ocrForLlm-html" ? (
          <Scrollbars>
            <OcrForLlmHtmlView
              html={(result as OcrForLlmInferenceResult)?.html || ""}
              hover={hover}
              select={select}
              disabled={dragging}
            />
          </Scrollbars>
        ) : null}
      </section>
    </div>
  );
}
