import type { SVGProps } from "react";

export function DownArrowColumn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path d="M0.428759 1.51629L2.80128 4.58195C2.95444 4.77985 3.29201 4.77984 3.44515 4.58193L5.81737 1.5161C5.98878 1.29457 5.80444 0.997866 5.49541 0.997877L0.750669 0.998046C0.44164 0.998057 0.25733 1.29478 0.428759 1.51629Z" />
    </svg>
  );
}
