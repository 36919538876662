import { z } from "zod";

export const ZodVertex = z.object({ x: z.number(), y: z.number() });
export type Vertex = z.infer<typeof ZodVertex>;

export const ZodBox = z.object({
  page: z.number(),
  vertices: z.array(ZodVertex),
});
export type Box = z.infer<typeof ZodBox>;

export const ZodProperty = z.object({
  boundingBoxes: z.array(ZodBox).optional(),
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  type: z.string(),
  value: z.string(),
  refinedValue: z.string(),
});
export type Property = z.infer<typeof ZodProperty>;

export const ZodGroupField = z.object({
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  properties: z.array(ZodProperty),
  type: z.string(),
  value: z.string(),
});
export type GroupField = z.infer<typeof ZodGroupField>;

export const ZodHeaderField = z.object({
  boundingBoxes: z.array(ZodBox),
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  type: z.literal("header"),
  value: z.string(),
  refinedValue: z.string(),
});
export type HeaderField = z.infer<typeof ZodHeaderField>;

export const ZodContentField = z.object({
  boundingBoxes: z.array(ZodBox).optional(),
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  type: z.string(),
  value: z.string(),
  refinedValue: z.string(),
});
export type ContentField = z.infer<typeof ZodContentField>;

export const ZodExtractorField = z.union([
  ZodGroupField,
  // ZodHeaderField,
  ZodContentField,
]);
export type ExtractorField = z.infer<typeof ZodExtractorField>;

export type ExtractorInferenceResult = ExtractorField[];

export const ZodExtractorOacJsonResponse = z.object({
  apiVersion: z.string(),
  confidence: z.number(),
  documentType: z.string(),
  fields: z.array(ZodExtractorField),
  imageBase64: z.string(),
  mimeType: z.string(),
  modelVersion: z.string(),
  stored: z.boolean(),
});
export type ExtractorOacJsonResponse = z.infer<
  typeof ZodExtractorOacJsonResponse
>;

export const ZodExtractorImageOacJson = ZodExtractorOacJsonResponse;
export type ImageExtractorOacJson = z.infer<typeof ZodExtractorImageOacJson>;

export const convertResponseToExtractorImageOacJson = (
  response: ExtractorOacJsonResponse,
): ImageExtractorOacJson => {
  return {
    ...response,
  };
};
