import { Icon, Icons } from "../components/Icon";

export interface DemoToolZoomProps {
  imageScale: number;
  onClickZoomIn: () => void;
  onClickZoomOut: () => void;
}
export function DemoToolZoom({
  imageScale,
  onClickZoomIn,
  onClickZoomOut,
}: DemoToolZoomProps): JSX.Element {
  return (
    <div className="flex items-center">
      <span
        onClick={e => onClickZoomOut()}
        className="flex items-center justify-center w-8 h-8 cursor-pointer"
      >
        <Icon source={Icons.ZoomOut} />
      </span>
      <span className="flex items-center justify-center text-sm font-light w-11">
        {Math.round(imageScale * 100)}%
      </span>
      <span
        onClick={e => onClickZoomIn()}
        className="flex items-center justify-center w-8 h-8 cursor-pointer"
      >
        <Icon source={Icons.ZoomIn} />
      </span>
    </div>
  );
}
