import type { SVGProps } from "react";

export function ErrorTriangle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9872 0.586539C10.7281 0.137821 10.0805 0.13782 9.82143 0.586538L0.495472 16.7404C0.236418 17.1891 0.560233 17.75 1.07834 17.75H19.7303C20.2484 17.75 20.5722 17.1891 20.3131 16.7404L10.9872 0.586539ZM11.3418 11.5V6.5H9.4668V11.5H11.3418ZM10.4043 15.25C11.0947 15.25 11.6543 14.6904 11.6543 14C11.6543 13.3097 11.0947 12.75 10.4043 12.75C9.71394 12.75 9.1543 13.3097 9.1543 14C9.1543 14.6904 9.71394 15.25 10.4043 15.25Z"
      />
    </svg>
  );
}
