import { useState } from "react";
import { Icon } from "../components/Icon";
import { Images } from "../components/Images";
import { Line } from "../types/ocrOacResponse";
import { classnames } from "../utils/classnames";

export interface OCRResultTableProps {
  lines: Line[];
  hover: {
    hoveredBoxIds: string[];
    setHoveredBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
  };
  select: {
    selectedBoxIds: string[];
    setSelectedBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
  };
  disabled?: boolean;
}

export function OcrTextView({
  lines,
  hover,
  select,
  disabled = false,
}: OCRResultTableProps): JSX.Element {
  const [isRowClicked, setIsRowClicked] = useState(false);

  // useEffect(() => {
  //   // 이미지뷰에서 박스가 클릭되었을때만 자동 스크롤
  //   if (isRowClicked) {
  //     setIsRowClicked(false);
  //     return;
  //   }

  //   if (select.selectedBoxIds.length) {
  //     const first = lines.find(line => isSelected(line))?.id;
  //     document
  //       .getElementById(`row-${first}`)
  //       ?.scrollIntoView({ behavior: "smooth", block: "center" });
  //   }
  // }, [select.selectedBoxIds]);

  const isHovered = (line: Line) => {
    if (hover.hoveredBoxIds.includes(line.id)) {
      return true;
    }
    const relatedWordIds = line.words.map(word => String(word.id));
    for (let wordId of relatedWordIds) {
      if (hover.hoveredBoxIds.includes(String(wordId))) {
        return true;
      }
    }
    return false;
  };

  const isSelected = (line: Line) => {
    if (select.selectedBoxIds.includes(line.id)) {
      return true;
    }
    const relatedWordIds = line.words.map(word => word.id);
    for (let wordId of relatedWordIds) {
      if (select.selectedBoxIds.includes(String(wordId))) {
        return true;
      }
    }
    return false;
  };

  return lines.length > 0 ? (
    <table className="w-[calc(100%-1.5rem)] table-fixed">
      <tbody className="flex flex-col items-start justify-start flex-1 rounded">
        {lines.map(line => {
          return (
            <tr
              className={classnames({
                "bg-indigo-400 bg-opacity-20 border-indigo-400 border-opacity-10":
                  !disabled && isHovered(line),
                "bg-white border-transparent": disabled || !isHovered(line),
                "border text-md px-2 py-1 font-normal text-left text-black text-opacity-90 break-words w-full rounded":
                  true,
              })}
              id={`row-${line.id}`}
              key={`${line.id}`}
              onMouseEnter={() =>
                hover.setHoveredBoxIds([
                  line.id,
                  ...line.words.map(word => String(word.id)),
                ])
              }
              onMouseLeave={() => hover.setHoveredBoxIds([])}
              onClick={() => {
                setIsRowClicked(true);
                select.setSelectedBoxIds([line.text]);
              }}
            >
              {line.text}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Icon source={Images.NoImage} className="mt-[5.5rem] mb-[1.5rem]" />
      <div className="text-sm font-light text-gray-500">
        No text was read from the image
      </div>
    </div>
  );
}
