import { Icon, Icons } from "../components/Icon";
import { classnames } from "../utils/classnames";
import { DemoToolZoom } from "./DemoToolZoom";

export interface DemoImageViewToolbarProps {
  fileVisible: boolean;
  imageScale: number;
  onImageScaleChange: (scale: number) => void;
  page: number;
  onPageSelect?: (index: number) => void;
  onRotate?(): void;
}

const MAX_ZOOM_RATIO = 3.0;
const MIN_ZOOM_RATIO = 0.2;

export function DemoImageViewToolbar({
  fileVisible,
  imageScale,
  onImageScaleChange,
  page,
  onPageSelect,
  onRotate,
}: DemoImageViewToolbarProps): JSX.Element {
  return (
    <div
      className={classnames({
        "absolute bottom-0 flex flex-row items-center justify-center text-black/90 flex-none w-full":
          true,
        invisible: !fileVisible,
      })}
    >
      <div className="relative flex items-center p-3 bg-white border rounded-md -top-2 h-11 border-black/20 gap-x-3">
        <div onClick={onRotate} className="w-4 h-4 cursor-pointer">
          <Icon source={Icons.Reset} />
        </div>
        <div className="bg-gray-300 w-[1px] h-4" />
        <DemoToolZoom
          imageScale={imageScale}
          onClickZoomIn={() => {
            imageScale < MAX_ZOOM_RATIO && onImageScaleChange(imageScale + 0.1);
          }}
          onClickZoomOut={() => {
            imageScale > MIN_ZOOM_RATIO && onImageScaleChange(imageScale - 0.1);
          }}
        />
      </div>
    </div>
  );
}
