import type { BaseLottieProps } from "./BaseLottie";
import { BaseLottie } from "./BaseLottie";
import animationData from "./Spinner.json";

export function Spinner({
  width = 20,
  height = 20,
  loop = true,
}: BaseLottieProps) {
  return (
    <BaseLottie
      animationData={animationData}
      width={width}
      height={height}
      loop={loop}
    />
  );
}
