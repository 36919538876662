import type { SVGProps } from "react";

export function NoImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="212"
      height="144"
      viewBox="0 0 212 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="64.3671"
        height="83.2622"
        rx="1.53945"
        transform="matrix(0.996947 -0.0780804 0.0772512 0.997012 65 32.0254)"
        fill="url(#paint0_linear_13014_71686)"
      />
      <rect
        width="65.6927"
        height="84.2114"
        rx="1.53945"
        transform="matrix(0.995807 0.0914828 -0.0905135 0.995895 81.584 27.5723)"
        fill="#F9FAFB"
      />
      <rect
        width="19.5518"
        height="4.61836"
        transform="matrix(0.995462 0.0951603 -0.0942515 0.995548 89.9531 38.8027)"
        fill="#E2E3E9"
      />
      <rect
        width="41.7029"
        height="4.61836"
        transform="matrix(0.995462 0.0951603 -0.0942515 0.995548 89.0527 47.7617)"
        fill="#E2E3E9"
      />
      <rect
        width="35.333"
        height="4.61836"
        transform="matrix(0.995462 0.0951603 -0.0942515 0.995548 88.2227 56.4453)"
        fill="#E2E3E9"
      />
      <rect
        width="17.9252"
        height="4.61836"
        transform="matrix(0.995172 0.0981447 -0.0972362 0.995261 83.9336 99.1953)"
        fill="#E2E3E9"
      />
      <rect
        width="10.7516"
        height="9.84719"
        transform="matrix(0.995519 0.0945595 -0.0935582 0.995614 122.113 97.5801)"
        fill="#E2E3E9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13014_71686"
          x1="37.2922"
          y1="-28.1982"
          x2="42.9595"
          y2="125.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2E3E9" />
          <stop offset="1" stopColor="#C6C8D2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
