import ErrorBoundary from "./ErrorBoundary";
import Home from "./pages/home";
import "./styles/tailwind.input.css";

function App() {
  return (
    // html, head, meta, link, body 태그는 index.html에 있음
    <div className="flex flex-col w-screen h-screen overflow-hidden">
      <ErrorBoundary>
        <Home />
      </ErrorBoundary>
    </div>
  );
}

export default App;
