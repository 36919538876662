import { useRef } from "react";
import { Icon, Icons } from "../components/Icon";

export interface DemoAddDataButtonProps {
  disabled?: boolean;
  className?: string;
  onUploadData?: (files: File[]) => void;
}

export function DemoAddDataButton({
  disabled = false,
  className = "",
  onUploadData,
}: DemoAddDataButtonProps): JSX.Element {
  const imageUploadFileRef = useRef<HTMLInputElement | null>(null);

  const onInputFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nFiles = e.target.files != null ? e.target.files.length : 0;
    if (nFiles > 0 && onUploadData) {
      const fileList = e.target.files as FileList;

      const files = Array.from(Array(fileList.length).keys()).map(
        idx => fileList.item(idx) as File,
      );
      onUploadData(files);
    }
  };
  return (
    <div className="w-full h-full">
      <button
        onClick={() => imageUploadFileRef.current?.click()}
        className="flex flex-col items-center justify-center w-full h-full text-xs font-normal text-indigo-400"
        disabled={disabled}
      >
        <Icon source={Icons.Plus} />
        Upload
      </button>
      <input
        type="file"
        ref={imageUploadFileRef}
        id="file"
        name="image"
        accept=".jpg,.jpeg,.png,.bmp,.pdf,.tif,.tiff,.heic"
        // multiple
        onChange={onInputFilesChange}
        style={{ display: "none" }}
      />
    </div>
  );
}
