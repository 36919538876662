import type { SVGProps } from "react";

export function ChevronLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99922 8L9.99922 13L10.6992 12.3L6.39922 8L10.6992 3.7L9.99922 3L4.99922 8Z"
      />
    </svg>
  );
}
