import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import { ImageOacJson } from "../types/demo";
import * as matrix from "../utils/drawing/matrix";
import * as rendering from "../utils/drawing/rendering";
import type { Transform } from "../utils/drawing/types";

export const useImageRendering = (
  file: ImageOacJson,
  imageScale: number,
  imageScaleChangeHandler: (scale: number) => void,
  setCssTransform: Dispatch<SetStateAction<string>>,
) => {
  const [transform, setTransform] = useState<Transform>({
    scale: 1,
    rotation: 0,
    tx: 0,
    ty: 0,
    matrix: matrix.identity(),
  });
  const [canvasSize, setCanvasSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const [offset, setMouseMoveOffset] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 },
  );

  useEffect(() => {
    if (!file) return;
    const t = rendering.getImageTransform(
      canvasSize.width,
      canvasSize.height,
      imageSize.width,
      imageSize.height,
    );
    setTransform(t);
    setCssTransform(rendering.getCssTransform(t));
    imageScaleChangeHandler(t.scale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasSize.width,
    canvasSize.height,
    file,
    imageSize.width,
    imageSize.height,
  ]);

  useEffect(() => {
    const t = rendering.zoomTo({
      newScale: imageScale,
      anchorx: canvasSize.width / 2,
      anchory: canvasSize.height / 2,
      xform: transform,
    });
    setTransform(t);
    setCssTransform(rendering.getCssTransform(t));
    imageScaleChangeHandler(t.scale);
  }, [imageScale]);

  // 마우스로 이미지를 이동시키는 이벤트 헨들러.
  useEffect(() => {
    const t = rendering.panTo({
      x: offset.x,
      y: offset.y,
      xform: transform,
    });
    setTransform(t);
    setCssTransform(rendering.getCssTransform(t));
  }, [offset]);

  const rotateBy = useCallback(
    (degree: number) => {
      const anchorx = canvasSize.width / 2;
      const anchory = canvasSize.height / 2;

      // 기존 transform에서 맵핑한 픽셀에서 출발해서 추가 회전을 한다.
      // 따라서 anchorx, anchory 좌표는 함수 호출 시점에 화면에 보이는 좌표를 중점으로
      // 회전을 시킨다.
      const newXform = rendering.rotateBy({
        degree,
        xform: transform,
        anchorx,
        anchory,
      });
      setTransform(newXform);
      setCssTransform(rendering.getCssTransform(newXform));
    },
    [canvasSize.height, canvasSize.width, setCssTransform, transform],
  );

  return {
    canvasSizeChangeHandler: setCanvasSize,
    canvasObjectMoveHandler: setMouseMoveOffset,
    imageSizeChangeHandler: setImageSize,
    rotateBy,
  };
};
