/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from "react";

export function ChevronUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.3L13 10.3L12.3 11L8 6.7L3.7 11L3 10.3L8 5.3Z"
      />
    </svg>
  );
}
