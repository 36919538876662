import type { SVGProps } from "react";

export function ZoomOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.5C12.0009 7.78067 11.5489 9.0204 10.724 10L14.5 13.793L13.793 14.5L10 10.724C9.0204 11.5489 7.78067 12.0009 6.5 12C5.41221 12 4.34884 11.6774 3.44437 11.0731C2.5399 10.4687 1.83495 9.60976 1.41867 8.60476C1.00238 7.59977 0.893465 6.4939 1.10568 5.42701C1.3179 4.36011 1.84173 3.3801 2.61092 2.61092C3.3801 1.84173 4.36011 1.3179 5.42701 1.10568C6.4939 0.893465 7.59977 1.00238 8.60476 1.41867C9.60976 1.83495 10.4687 2.5399 11.0731 3.44437C11.6774 4.34884 12 5.41221 12 6.5ZM3.99994 10.2416C4.73996 10.7361 5.60999 11 6.5 11C7.69307 10.9987 8.83689 10.5241 9.68052 9.68052C10.5241 8.83689 10.9987 7.69307 11 6.5C11 5.60999 10.7361 4.73996 10.2416 3.99994C9.74715 3.25992 9.04434 2.68314 8.22208 2.34254C7.39981 2.00195 6.49501 1.91284 5.6221 2.08647C4.74918 2.2601 3.94736 2.68869 3.31802 3.31802C2.68869 3.94736 2.2601 4.74918 2.08647 5.6221C1.91284 6.49501 2.00195 7.39981 2.34254 8.22208C2.68314 9.04434 3.25992 9.74715 3.99994 10.2416ZM4 6H9V7H4V6Z"
        fill="currentColor"
      />
    </svg>
  );
}
